// Montserrat

@font-face {
  font-family: Montserrat400;
  src: url(../fonts/Montserrat/static/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat500;
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat600;
  src: url(../fonts/Montserrat/static/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat700;
  src: url(../fonts/Montserrat/static/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat800;
  src: url(../fonts/Montserrat/static/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: Montserrat900;
  src: url(../fonts/Montserrat/static/Montserrat-Black.ttf);
}

// Noteworthy

@font-face {
  font-family: NoteworthyLt;
  src: url(../fonts/Noteworthy/Noteworthy-Lt.ttf);
}
