.requestHelp {
  &--content {
    p {
      line-height: 27px;
      font-size: 18px;
      margin-bottom: 16px;

      @media (min-width: 962px) {
        padding-right: 100px;
      }
    }
  }
}
