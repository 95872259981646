.modalManualContent {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 64px 92px;
  background-color: #e7e7e7;

  @media (max-width: 768px) {
    padding: 64px 0;
    background-color: $white;
  }

  & + .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    border: 0;
    background-color: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .1s ease-in;

    &:hover {
      background-color: #eee;
    }
  }

  .thumbnails {
    position: absolute;
    height: calc(100vh - 128px);
    width: 100px;
    padding: 48px 0;

    @media (max-width: 768px) {
      display: none;
    }

    .slick-slider {
      height: 100%;
    }

    .slick-list {
      height: 100% !important;
    }

    .slick-slide {
      &.slick-current {
        img {
          border: 2px solid #babd42;
          box-shadow: 0 4px 15px rgba(186, 189, 66, 0.15);
        }
      }
    }

    .slideImage {
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .slick-next,
    .slick-prev {
      width: 100px;
      height: 40px;
      z-index: 9;
      left: 50%;
      overflow: hidden;
      transform: translate(-50%, 0);

      &:before {
        content: '';
        display: block;
        width: 100px;
        height: 40px;
        background-image: url('../../assets/images/slide-prev.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &.slick-disabled {
        opacity: .3;
      }
    }

    .slick-prev {
      top: -48px;

      &:before {
        transform: rotate(90deg);
      }
    }

    .slick-next {
      bottom: -48px;
      top: auto;

      &:before {
        transform: rotate(270deg);
      }
    }
  }

  .slides {
    overflow: hidden;
    height: calc(100vh - 128px);
    padding: 0 150px;

    @media (max-width: 1200px) {
      padding: 0 0 0 100px;
    }

    @media (max-width: 768px) {
      padding: 0;
    }

    .slideContent {}

    .slideImage {
      display: flex;
      justify-content: center;

      img {
        max-height: calc(100vh - 128px);
      }
    }

    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
      z-index: 9;
      background-size: 40px 40px;

      &.slick-disabled {
        opacity: .3;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      content: '';
    }

    .slick-prev {
      left: 50px;
      background-image: url('../../assets/images/slide-prev.svg');
    }

    .slick-next {
      right: 50px;
      background-image: url('../../assets/images/slide-next.svg');
    }
  }

  .slider {
    display: none;

    @media (max-width: 375px) {
      display: block;
    }
  }
}


