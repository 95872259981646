footer {
  font-size: 14px;
  padding: 32px 0 0;
  background-color: $white;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .footer {
    .container {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;

      @media (min-width: 1200px) {
        grid-column-gap: 164px;
      }

      @media (min-width: 962px) {
        grid-column-gap: 92px;
      }

      @media (max-width: 961px) {
        grid-template-columns: 1.5fr 1fr 1fr;
        grid-column-gap: 16px;
      }

      @media (max-width: 600px) {
        display: block;
      }
    }

    &--col {
      &:not(:last-child) {
        @media (max-width: 600px) {
          margin-bottom: 32px;
        }
      }
    }

    &--socials {
      list-style-type: none;
      margin: 8px 0 0;
      padding: 0;

      li {
        margin-right: 16px;
        display: inline-block;
      }

      img {
        display: block;
        height: 24px;
      }
    }

    p {
      margin: 0;
      line-height: 22px;
    }

    .footer--title {
      font-family: 'Montserrat600', sans-serif;
      line-height: 21px;
      margin-bottom: 8px;

      &:not(:first-child) {
        margin-top: 12px;

        @media (max-width: 600px) {
          margin-top: 32px;
        }
      }

      span {
        font-family: 'Montserrat400', sans-serif;
      }
    }

    .footer--link {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .subfooter {
    margin-top: 32px;
    background: rgba(150, 173, 199, 0.1);
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
