.copyToClipboard {
  display: flex;
  align-items: flex-start;

  @media (max-width: 600px) {
    justify-content: space-between;
  }

  &--value {
    font-family: 'Montserrat600', sans-serif;
    font-size: 16px;
    margin-right: 4px;
  }

  &--iconWrapper {
    position: relative;
    cursor: pointer;
    height: 40px;
    width: 40px;

    &:hover {
      background: rgba(0, 102, 204, 0.1);
      border-radius: 4px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--status {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    padding: 6px 8px;
    border-radius: 4px;
    font-family: 'Montserrat600', sans-serif;
    font-size: 12px;
    line-height: 18px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.2);

    @media (max-width: 600px) {
      top: 50%;
      left: auto;
      right: 48px;
      transform: translateY(-50%);
    }

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      left: 50%;
      bottom: -8px;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $white;

      @media (max-width: 600px) {
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #fff;
        right: -16px;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.hide {
      display: none;
    }
  }
}
