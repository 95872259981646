@import 'assets/scss/common';

* {
  box-sizing: border-box;
}

html {
  //scroll-behavior: smooth;
}

body {
  color: $baseColor;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: 'Montserrat400', sans-serif;
}

a {
  color: $primary;
}

p {
  margin: 0 0 4px;
}

img {
  max-width: 100%;
  display: block;
}

#root {
  height: 100vh;
}

// -----------------------------
// ---------- LAYOUT -----------
// -----------------------------

.pageWrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.container {
  margin: 0 auto;

  @media (min-width: 769px) {
    width: 750px;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  @media (min-width: 962px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;

  @media (max-width: 1199px) {
    grid-column-gap: 0;
  }

  @media (max-width: 961px) {
    display: block;
  }
}

.nowrap {
  white-space: nowrap;
}

// -----------------------------
// ---------- BUTTONS ----------
// -----------------------------

.button {
  display: inline-block;
  height: 46px;
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  padding: 12px 24px;
  font-family: 'Montserrat500', sans-serif;
  text-decoration: none;
  text-align: center;

  @media (min-width: 601px) {
    min-width: 190px;
    padding: 12px 36px;
  }

  @media (max-width: 375px) {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
  }

  &--primary {
    background-color: $primary;
    color: $white;

    &:hover {
      border: 1px solid #004c99;
      background-color: #004c99;
    }

    &:active {
      border: 1px solid #003366;
      background-color: #003366;
    }

    &:disabled,
    &[disabled] {
      cursor: default;
      border: 1px solid #d9dadb;
      background-color: #d9dadb;
      color: #768594;
    }
  }

  &--secondary {
    color: $primary;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &--green {
    background-color: $green;
    border-color: $green;
    color: $baseColor;

    &:hover {
      background-color: $greenDark;
    }
  }

  &--full {
    width: 100%;
  }
}

// -----------------------------
// ---------- HEADERS ----------
// -----------------------------

.h {
  display: flex;
  margin-top: 0;
  font-family: 'Montserrat700', sans-serif;

  img {
    margin-right: 12px;
  }
}

.h2 {
  font-size: 36px;
  letter-spacing: 1px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
  }
}

.h3 {
  font-size: 24px;
  line-height: 97.5%;
}

.h4 {
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0.5px;
  margin-bottom: 32px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.h5 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.h6 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  margin-top: 16px;
}

// -----------------------------
// ---------- HELPERS ----------
// -----------------------------

.uppercase {
  text-transform: uppercase;
}

.hero {
  overflow: hidden;
  padding: 100px 0 80px;
  background: radial-gradient(123.52% 293.76% at 12.62% 18.55%, rgba(150, 173, 199, 0.41) 0%, rgba(223, 231, 238, 0) 100%);

  @media (max-width: 961px) {
    padding: 64px 0 48px;
  }
}

.mobile {
  &__hidden {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__visible {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

// -----------------------------
// ---------- IMPORTS ----------
// -----------------------------

@import 'pages/Home/Home';
@import 'pages/Donate/Donate';

@import 'components/Breadcrumbs/Breadcrumbs';
@import 'components/CopyTo/CopyTo';
@import 'components/Footer/Footer';
@import 'components/Header/Header';
@import 'components/LoadingSpinner/LoadingSpinner';
@import 'components/PaymentDetails/PaymentDetails';
@import 'components/ProgressCard/ProgressCard';
@import 'components/RequestHelp/RequestHelp';
@import 'components/RequestHelpForm/RequestHelpForm';
@import 'components/Summary/Summary';
@import 'components/Manual/Manual';
@import 'components/ManualModal/ManualModal';
