.breadcrumbs {
  margin: 32px 0 32px 50px;

  @media (max-width: 768px) {
    display: none;
  }

  a {
    display: inline-flex;
    align-items: center;
    color: $baseColor;
    text-decoration: none;
  }
}
