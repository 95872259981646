.requestHelpForm {
  input,
  textarea {
    font-family: 'Montserrat400', sans-serif;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 100%;
    margin-top: 4px;
    padding: 10px;
    color: $baseColor;
    outline: none;
  }

  input {
    height: 36px;
  }

  textarea {
    resize: vertical;
  }

  label {
    font-size: 14px;
    display: block;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  div {
    opacity: 0.7;
    margin-bottom: 16px;
    margin-top: 4px;
    font-size: 14px;
  }

  button {
    margin-top: 24px;
  }

  .error {
    border-color: rgba(208, 2, 27, 1);
  }
}
