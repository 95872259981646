.paymentDetails {
  &.isEuro {
    padding-bottom: 16px;
    border-bottom: 2px solid rgba(21, 21, 21, 0.7);
  }

  @media (max-width: 961px) {
    margin-bottom: 32px;
  }

  p {
    margin: 16px 0 0;
    line-height: 20px;

    &.swift {
      margin-top: 0;
    }

    span {
      margin-left: 4px;
      font-family: 'Montserrat600', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #039;
    }
  }

  .isRecommended {
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 32px;

    &--label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: -8px;

      &:before,
      &:after {
        width: 100%;
        content: '';
        height: 2px;
        background-color: rgba(21, 21, 21, 0.7);
      }

      span {
        padding: 0 12px;
        color: $baseColor;
        font-size: 14px;
        font-family: 'Montserrat500', sans-serif;
        white-space: nowrap;
      }
    }
  }

  .copyToClipboard--value {
    max-width: 90%;
    padding: 8px 0;
  }
}
