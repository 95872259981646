.header {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 0 20px 0 50px;
  position: sticky;
  background: $white;
  top: 0;
  z-index: 11;

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  &--logo {
    display: flex;
    align-items: center;
    color: $baseColor;
    height: 46px;
    font-family: 'Montserrat900', sans-serif;
    font-size: 14px;
    line-height: 97.5%;
    letter-spacing: 1px;
    text-decoration: none;

    img {
      @media (min-width: 601px) {
        margin-right: 16px;
      }
    }

    span {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  &--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }

  &--rightContent {
    display: flex;
    align-items: center;
  }

  &--languageToggler {
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;

      &:first-child {
        border-right: 1px solid $baseColor;
      }
    }

    button {
      cursor: pointer;
      border: 0;
      background: none;
      font-family: 'Montserrat700', sans-serif;
      font-size: 16px;
      line-height: 20px;
      opacity: 0.5;
      padding: 6px 14px;

      &.active {
        opacity: 1;
        cursor: default;
      }
    }
  }

  .button--donate {
    margin-left: 24px;

    @media (max-width: 600px) {
      margin-left: 12px;
    }
  }
}
