.progressCard {
  max-width: 346px;
  background-color: $white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 600px) {
    max-width: none;
  }

  &--image {
    height: 430px;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--details {
    padding: 24px 32px 44px;

    p {
      font-family: 'Montserrat600', sans-serif;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.progressBar {
  margin-top: 24px;

  &--details {
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat700', sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
  }

  &--progress {
    position: relative;
    height: 8px;
    border-radius: 8px;
    background-color: #e5e5e5;
  }

  &--line {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #1ba040;

    &:after {
      position: absolute;
      content: '';
      right: -6px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $white;
      border: 2px solid #178c38;
    }
  }
}
