.summary {
  padding: 72px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  
  & > div {
    display: flex;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }

    &:not(:last-child) {
      @media (max-width: 768px) {
        margin-bottom: 44px;
      }
    }
  }

  img {
    margin-right: 24px;
    max-width: 56px;

    @media (min-width: 769px) and (max-width: 961px) {
      margin-right: 20px;
    }
  }

  h3 {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat800', sans-serif;
    font-size: 32px;
    line-height: 40px;
    color: #042843;
    white-space: nowrap;

    @media (max-width: 961px) {
      font-size: 26px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 27px;

    @media (max-width: 961px) {
      font-size: 16px;
    }
  }

  a {
    display: block;
    font-family: 'Montserrat500', sans-serif;
    font-size: 16px;
    line-height: 27px;
    text-decoration: underline;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}
