.home {
  position: relative;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  &:before {
    background: url(../../assets/images/home-image.jpeg) no-repeat top left;
    background-size: cover;
    content: '';
    width: 40%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1200px) {
      background-position: top left 20%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &--content {
    @media (max-width: 961px) {
      max-width: 450px;
    }

    @media (max-width: 768px) {
      max-width: none;
    }
  }

  &--title {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 46px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 32px;
    font-family: 'Montserrat900', sans-serif;

    @media (max-width: 375px) {
      font-size: 40px;
    }

    span {
      display: block;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 12px;
      letter-spacing: 0.5px;
      font-family: 'Montserrat700', sans-serif;
    }
  }

  &--description {
    font-family: 'Montserrat500', sans-serif;
    font-size: 16px;
    line-height: 24px;
    max-width: 400px;
    margin-bottom: 44px;

    @media (max-width: 768px) {
      max-width: none;
    }
  }

  &--goal {
    margin-bottom: 44px;

    @media (max-width: 600px) {
      display: none;
    }

    .h3 {
      line-height: 36px;
    }
  }

  .button {
    margin-bottom: 16px;

    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .progressCard {
    display: none;

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 24px;
    }
  }
}
