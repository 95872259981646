.manual {
  @media (max-width: 768px) {
    padding-top: 24px;
  }

  &--title {
    margin-bottom: 16px;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 64px 16px 0;
    }

    @media (max-width: 600px) {
      padding-top: 32px;
    }
  }

  &--subtitle {
    margin-bottom: 32px;
    justify-content: center;

    @media (max-width: 768px) {
      display: block;
      padding: 0 16px;
    }

    @media (max-width: 600px) {
      font-size: 20px;
    }

    span {
      font-family: 'Montserrat400', sans-serif;
      font-size: 18px;
      text-transform: initial;
      margin-left: 16px;

      @media (max-width: 500px) {
        display: block;
        margin: 0;
      }
    }
  }

  .container {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .slideContent {
    position: relative;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 504px;
    overflow: hidden;

    @media (max-width: 1199px) {
      height: auto;
    }

    @media (max-width: 768px) {
      height: 483px;
    }

    .hover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      border-radius: 4px;
      cursor: pointer;
    }

    &:hover {
      @media (min-width: 769px) {
        .hover {
          display: block;
          background: rgba(0, 0, 0, 0.02);
          border: 2px solid #babd42;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .slideImage {
    display: flex;
    justify-content: center;
    object-fit: contain;
  }

  .slick-list {
    height: auto !important;
  }

  .slick-slide {
    padding: 0 16px;

    @media (max-width: 1199px) {
      height: auto;
    }

    @media (max-width: 768px) {
      padding: 0 8px;
      height: 483px;
    }
  }

  .slick-dots {
    bottom: -40px;

    li {
      margin: 0;
      height: auto;
      width: auto;
      background-color: $white;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      button {
        padding: 0;
        margin: 0;
        height: 8px;
        width: 24px;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        z-index: 2;

        button {
          background-color: $primary;
          border-radius: 8px;
          transition: transform ease-in 0.3s;
          z-index: 9;

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 9;
    background-size: 40px 40px;

    &.slick-disabled {
      opacity: .3;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    content: '';
  }

  .slick-prev {
    background-image: url('../../assets/images/slide-prev.svg');
  }

  .slick-next {
    background-image: url('../../assets/images/slide-next.svg');
  }

  .ctaButtonWrapper {
    display: flex;
    justify-content: center;
    padding-top: 90px;
  }
}

.slider {
  margin: 0 auto;
  max-width: 530px;
  cursor: pointer;
  top: 32px;

  @media (max-width: 562px) {
    margin: 0 16px;
    top: 24px;
  }

  .sliderThumb {
    background-color: $primary;
    height: 8px;
    width: 24px;
    border-radius: 8px;
    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      transform: scale(1.5);
    }
  }

  .sliderTrack {
    background-color: $white;
    border-radius: 8px;
    height: 8px;
  }
}
